import { FunctionComponent } from "react";
import { Box, Button, HStack } from "@chakra-ui/react";
import { INTERNAL_LINKS, NavLink, SOCIAL_LINKS, SocialLink } from "./Navbar";
import scrollToElement from "../utils/scrollToElement";

const Footer: FunctionComponent = () => {
  return (
    <div className="footer-container">
      <div
        className="footer-rounded-div bg-[#fafafc]"
        data-scroll-section
        data-scroll-section-id="footer"
      >
        <div className="rounded-div-wrap">
          <div className="rounded-div bg-[#fafafc]" />
        </div>
      </div>
      <div className="footer-wrap footer-footer-wrap text-white sticky bottom-0 z-[0] bg-[#1c1d20] w-full h-screen overflow-hidden flex flex-col p-5 pt-[64px] xl:p-[64px_104px] box-border items-center justify-center gap-[48px] text-left text-white font-plus-jakarta-sans">
        <footer
          className="section footer"
          data-scroll=""
          data-scroll-speed="-4"
          data-scroll-position="bottom"
        >
          <img
            className="absolute m-[0_!important] top-[0px] w-[2720px] h-[134px] xl:h-[252px] shrink-0 z-[0] opacity-50"
            alt=""
            src="../svg/lines.svg"
          />
          <div className="max-w-[800px] mx-auto flex flex-col items-center justify-start gap-[32px] text-center z-10 text-4xl lg:text-6xl font-grifter">
            <b className="flex items-center justify-center w-auto ">
              See if I'm the right fit for your team
            </b>

            <Button
              variant="solid"
              bgColor="transparent"
              border="1px solid white"
              color="white"
              borderRadius={20}
              className="relative overflow-hidden group flex justify-center w-full xl:w-[750px] p-12 xl:p-[60px] mt-8 xl:mt-[60px]"
              as="a"
              href="mailto:ovidiuc224@gmail.com"
              _hover={{
                backgroundColor: "white",
                color: "black",
              }}
              _active={{
                color: "black",
                backgroundColor: "whiteAlpha.800",
              }}
              fontSize={17}
            >
              <div className="relative overflow-hidden group w-full flex justify-center text-3xl">
                <span className="invisible">Get in touch</span>
                <span className="absolute top-0 group-hover:-translate-y-full transition-transform ease-in-out duration-300 hover:duration-300">
                  Get in touch
                </span>
                <span className="absolute top-0 translate-y-full group-hover:translate-y-0 transition-transform ease-in-out duration-300 hover:duration-300">
                  Get in touch
                </span>
              </div>
            </Button>
          </div>
          <div className="max-w-6xl mx-auto mt-28 ">
            <div className="flex flex-col xl:flex-row content-center items-center justify-between gap-8 xl:gap-0">
              <Box
                onClick={() => scrollToElement("#hero", "start", "start")}
                className="cursor-pointer font-grifter text-3xl font-bold mx-auto xl:mr-auto xl:ml-0"
              >
                ovi<span className="opacity-30 text-white">diu</span>
                <span className="opacity-20 text-white">co</span>
              </Box>
              <HStack spacing={8} alignItems={"center"}>
                <HStack
                  spacing={12}
                  className="flex-row flex-wrap justify-center xl:flex-nowrap"
                >
                  {INTERNAL_LINKS.map(({ selector, title }) => (
                    <NavLink
                      darkMode
                      key={title}
                      selector={selector}
                      className="no-mobile-margin w-4/12 xl:w-full"
                    >
                      {title}
                    </NavLink>
                  ))}
                </HStack>
              </HStack>
            </div>
            <div className="h-[1px] bg-gray-400 w-full my-10" />
            <div className="flex gap-4 justify-between">
              {SOCIAL_LINKS.map(({ link, title, icon }) => (
                <SocialLink title={title} icon={icon} link={link} key={title} />
              ))}
            </div>
          </div>
        </footer>
        <div className="overlay overlay-gradient"></div>
      </div>
    </div>
  );
};

// <h4 className="span-lines animate">
//   <span className="span-line">
//     <span className="span-line-inner">Helping</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">brands</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">to</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">stand</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">out</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">in</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">the</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">digital</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">era.</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">Together</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">we</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">will</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">set</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">the</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">new</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">status</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">quo.</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">No</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">nonsense,</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">always</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">on</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">the</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">cutting</span>
//   </span>
//   <span className="span-line">
//     <span className="span-line-inner">edge.</span>
//   </span>
// </h4>

export default Footer;
