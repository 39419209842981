export default function (
  elementSelector: string,
  block: ScrollLogicalPosition = "center",
  inline: ScrollLogicalPosition = "nearest"
) {
  const element = document.querySelector(elementSelector);

  element.scrollIntoView({
    behavior: "smooth",
    block,
    inline,
  });
}
