import { FunctionComponent, useState } from "react";
import {
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Tag,
  useDisclosure
} from "@chakra-ui/react";
import Lottie from "lottie-react";

import NotificationAnimation from "../animations/notification-V3.json";
import InfinityAnimation from "../animations/infinity.json";
import FavouriteAnimation from "../animations/favourite.json";
import ActivityAnimation from "../animations/activity.json";
import { motion } from "framer-motion";
import { FaExternalLinkAlt } from "react-icons/fa";
import Marquee from "./Marquee";

const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;
const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 30px)`;

const SUSHI_PIC_URLS = [
  "https://res.cloudinary.com/dldgxw9w6/image/upload/f_auto/v1665732246/sushi3_dejv0m.jpg",
  "https://res.cloudinary.com/dldgxw9w6/image/upload/f_auto/v1665732246/sushi1_qcpcif.jpg",
  "https://res.cloudinary.com/dldgxw9w6/image/upload/f_auto/v1665732246/sushi4_j9ino3.jpg",
  "https://res.cloudinary.com/dldgxw9w6/image/upload/f_auto/v1665732246/sushi2_zjyou8.jpg",
];

const Hero: FunctionComponent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoaded, setIsLoaded] = useState(false);

  const renderModal = () => (
    <Modal onClose={onClose} size="6xl" isOpen={isOpen}>
      <ModalOverlay
        bg="blackAlpha.600"
        backdropFilter="blur(10px) hue-rotate(90deg)"
      />
      <ModalContent className="w-[1280px]" bg="#f5f5f7">
        <ModalHeader className="font-plus-jakarta-sans">
          Here are some random pics of my cat, Sushi 🍣
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="w-full grid grid-cols-4 gap-3">
          {SUSHI_PIC_URLS.map((url, idx) => {
            return (
              <motion.div
                key={idx}
                initial={false}
                className={`col-span-2 rounded-md h-[300px]`}
                animate={
                  isLoaded
                    ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
                    : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
                }
                transition={{ duration: 1, delay: parseFloat(`0.${idx * 2}`) }}
              >
                <Image
                  boxSize={idx % 3 === 0 ? "full" : "full"}
                  height={"full"}
                  objectFit="cover"
                  src={url}
                  alt=""
                  onLoad={() => setIsLoaded(true)}
                />
              </motion.div>
            );
          })}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="solid"
            bgColor="black"
            color="white"
            _hover={{
              backgroundColor: "blackAlpha.800",
              color: "white",
            }}
            _active={{
              backgroundColor: "blackAlpha.600",
            }}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

  return (
    <div id="hero" className="w-full h-full text-left text-black">
      <div className="bg-black overflow-hidden flex flex-col items-start justify-start text-left xl:text-center text-4xl lg:text-6xl text-white font-grifter">
        <img
          className="absolute m-[0_!important] top-[0px] w-[2720px] h-[974.35px] xl:h-[768.35px] shrink-0 z-[0]"
          alt=""
          src="../svg/lines.svg"
        />
        <div className="p-5 self-stretch flex flex-col xl:p-[104px] box-border items-center justify-center gap-[32px] z-[1]">
          <div className="flex flex-col items-center justify-start gap-[16px]">
            <Tag className="rounded-2xl border text-white bg-black  text-2xl px-4 py-2 font-bold font-plus-jakarta-sans mb-2">Currently frontend engineering at Nexoya</Tag>
            <b className="leading-none xl:leading-[64px] inline-block max-w-3xl">
              I'm Ovi - a Full Stack Developer with a keen 👀 for design
            </b>
            <div className="text-base leading-tight  xl:text-xl xl:leading-[24px] font-plus-jakarta-sans text-gray-700 inline-block max-w-2xl">
              On a journey to create stuff that I'm proud of, overly excited
              about developing jaw-dropping interfaces, and got a deep obsession
              to always improve myself
            </div>
          </div>
          <div className="flex flex-col xl:flex-row items-start justify-start gap-4 w-full xl:w-auto">
            <Button
              variant="solid"
              bgColor="white"
              color="black"
              className="relative overflow-hidden group w-full flex justify-center py-8"
              border="1px solid white"
              as="a"
              href="mailto:ovidiuc224@gmail.com"
              _hover={{
                backgroundColor: "whiteAlpha.300",
                color: "white",
              }}
              _active={{
                backgroundColor: "blackAlpha.500",
              }}
            >
              <div className="relative overflow-hidden group w-full flex justify-center text-base xl:text-2xl px-16">
                <span className="invisible">Contact me</span>
                <span className="absolute top-0 group-hover:-translate-y-full transition-transform ease-in-out duration-300 hover:duration-300">
                  Contact me
                </span>
                <span className="absolute top-0 translate-y-full group-hover:translate-y-0 transition-transform ease-in-out duration-300 hover:duration-300">
                  Contact me
                </span>
              </div>
            </Button>
            <Button
              bgColor="black"
              className="relative overflow-hidden group w-full flex justify-center py-8"
              onClick={() =>
                window.open(
                  "https://res.cloudinary.com/dldgxw9w6/image/upload/v1670775773/Ovidiu_Cotorogea_Ovi_Resume_vo2awq.pdf",
                  "_blank"
                )
              }
              color="white"
              border="1px solid white"
              _hover={{
                backgroundColor: "whiteAlpha.300",
                color: "white",
              }}
              _active={{
                backgroundColor: "blackAlpha.500",
              }}
              fontSize={17}
            >
              <div className="relative overflow-hidden group w-full flex justify-center text-base xl:text-2xl">
                <span className="items-baseline invisible flex">
                  Download CV <FaExternalLinkAlt className="ml-2" size={16} />
                </span>
                <span className="items-baseline flex absolute top-0 group-hover:-translate-y-full transition-transform ease-in-out duration-300 hover:duration-300">
                  Download CV <FaExternalLinkAlt className="ml-2" size={16} />
                </span>
                <span className="items-baseline flex absolute top-0 translate-y-full group-hover:translate-y-0 transition-transform ease-in-out duration-300 hover:duration-300">
                  Download CV <FaExternalLinkAlt className="ml-2" size={16} />
                </span>
              </div>
            </Button>
          </div>
        </div>
        <div className="py-8 flex-[1] self-stretch relative z-[2]">
          <div className="w-full flex flex-row items-center justify-center gap-[20px]">
            <Marquee
              direction="right"
              speed={70}
              gradient
              gradientColor={[0, 0, 0]}
              play
            >
              <div className="hover:scale-105 gradient-shadow transition-all mx-2 xl:mx-5 my-11 relative rounded-[6px] w-[200px] h-[200px] xl:w-[400px] xl:h-[400px] shrink-0 object-cover">
                <img
                  className="rounded-[6px] w-[200px] h-[200px] xl:w-[400px] xl:h-[400px] shrink-0 object-cover"
                  alt=""
                  src="https://res.cloudinary.com/dldgxw9w6/image/upload/w_800,f_auto/v1666078307/1_jw6awy.jpg"
                />
              </div>
              <div className="hover:scale-105 gradient-shadow transition-all mx-2 xl:mx-5 my-11 relative rounded-[6px] w-[200px] h-[200px] xl:w-[400px] xl:h-[400px] shrink-0 object-cover">
                <img
                  className="rounded-[6px] w-[200px] h-[200px] xl:w-[400px] xl:h-[400px] shrink-0 object-cover"
                  alt=""
                  src="https://res.cloudinary.com/dldgxw9w6/image/upload/w_800,f_auto/v1666078307/2_m3wf44.jpg"
                />
              </div>
              <div className="hover:scale-105 gradient-shadow transition-all mx-2 xl:mx-5 my-11 relative rounded-[6px] w-[200px] h-[200px] xl:w-[400px] xl:h-[400px] shrink-0 object-cover">
                <img
                  className="rounded-[6px] w-[200px] h-[200px] xl:w-[400px] xl:h-[400px] shrink-0 object-cover"
                  alt=""
                  src="https://res.cloudinary.com/dldgxw9w6/image/upload/w_800,f_auto/v1666078307/3_re9wtt.jpg"
                />
              </div>
              <div className="hover:scale-105 gradient-shadow transition-all mx-2 xl:mx-5 my-11 relative rounded-[6px] w-[200px] h-[200px] xl:w-[400px] xl:h-[400px] shrink-0 object-cover">
                <img
                  className="rounded-[6px] w-[200px] h-[200px] xl:w-[400px] xl:h-[400px] shrink-0 object-cover"
                  alt=""
                  src="https://res.cloudinary.com/dldgxw9w6/image/upload/w_800,f_auto/v1666078308/4_zaxf8q.jpg"
                />
              </div>
              <div className="hover:scale-105 gradient-shadow transition-all mx-2 xl:mx-5 my-11 relative rounded-[6px] w-[200px] h-[200px] xl:w-[400px] xl:h-[400px] shrink-0 object-cover">
                <img
                  className="relative rounded-[6px] w-[200px] h-[200px] xl:w-[400px] xl:h-[400px] shrink-0 object-cover"
                  alt=""
                  src="https://res.cloudinary.com/dldgxw9w6/image/upload/w_800,f_auto/v1666078308/5_dyblfy.jpg"
                />
              </div>{" "}
              <div className="hover:scale-105 gradient-shadow transition-all mx-2 xl:mx-5 my-11 relative rounded-[6px] w-[200px] h-[200px] xl:w-[400px] xl:h-[400px] shrink-0 object-cover">
                <img
                  className="relative rounded-[6px] w-[200px] h-[200px] xl:w-[400px] xl:h-[400px] shrink-0 object-cover"
                  alt=""
                  src="https://res.cloudinary.com/dldgxw9w6/image/upload/w_800,f_auto/v1666078308/6_ey012d.jpg"
                />
              </div>
            </Marquee>
          </div>
        </div>
      </div>
      <div className="bg-black h-full flex flex-col p-[0px_0px_64px] box-border items-start justify-start">
        <div className="bg-black w-full overflow-hidden flex flex-col p-5 xl:p-[64px_24px] 2xl:p-[64px_104px] box-border items-center justify-center gap-[32px]">
          <div className="flex flex-row flex-wrap items-center content-between justify-center xl:self-stretch xl:items-center xl:justify-between">
            <img
              style={{
                filter:
                  "invert(100%) sepia(100%) saturate(0%) hue-rotate(18deg) brightness(108%) contrast(101%)",
              }}
              className="w-auto h-[32px] xl:shrink-0 m-3"
              alt=""
              src="../logos/logo-walmart.svg"
            />
            <img
              style={{
                filter:
                  "invert(100%) sepia(100%) saturate(0%) hue-rotate(18deg) brightness(108%) contrast(101%)",
              }}
              className="w-auto h-[32px] xl:shrink-0 m-3"
              alt=""
              src="../logos/logo-f64.svg"
            />
            <img
              style={{
                filter:
                  "invert(100%) sepia(100%) saturate(0%) hue-rotate(18deg) brightness(108%) contrast(101%)",
              }}
              className="w-auto h-[32px] xl:shrink-0 m-3"
              alt=""
              src="../logos/logo-carrefour.svg"
            />
            <img
              style={{
                filter:
                  "invert(100%) sepia(100%) saturate(0%) hue-rotate(18deg) brightness(108%) contrast(101%)",
              }}
              className="w-auto h-[32px] xl:shrink-0 m-3"
              alt=""
              src="../logos/logo-floria.svg"
            />
            <img
              style={{
                filter:
                  "invert(100%) sepia(100%) saturate(0%) hue-rotate(18deg) brightness(108%) contrast(101%)",
              }}
              className="w-auto h-[32px] xl:shrink-0 m-3"
              alt=""
              src="../logos/logo-shopify.svg"
            />{" "}
            <img
              style={{
                filter:
                  "invert(100%) sepia(100%) saturate(0%) hue-rotate(18deg) brightness(108%) contrast(101%)",
              }}
              className="w-auto h-[32px] xl:shrink-0 m-3"
              alt=""
              src="../logos/logo-vtex.svg"
            />
            <img
              style={{
                filter:
                  "invert(100%) sepia(100%) saturate(0%) hue-rotate(18deg) brightness(108%) contrast(101%)",
              }}
              className="w-auto h-[32px] xl:shrink-0 m-3"
              alt=""
              src="../logos/logo-elefant.svg"
            />
            <img
              style={{
                filter:
                  "invert(100%) sepia(100%) saturate(0%) hue-rotate(18deg) brightness(108%) contrast(101%)",
              }}
              className="w-auto h-[32px] xl:shrink-0 m-3"
              alt=""
              src="../logos/logo-miniprix.svg"
            />
          </div>
        </div>

        <div className="flex-[1] p-5 overflow-hidden self-stretch bg-black flex-wrap xl:flex-nowrap flex flex-row p-[64px_0px] box-border items-center justify-center gap-[64px] text-center text-white font-grifter">
          <div className="w-full max-w-[437px] shrink-0 flex flex-col p-5 xl:p-[0px_64px_0px_104px] box-border items-end justify-center gap-[48px]">
            <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
              <div className="bg-[#f5f5f7] w-[64px] h-[64px] rounded-3xl flex justify-center items-center">
                <Lottie
                  style={{ height: 48, width: 48 }}
                  animationData={InfinityAnimation}
                  loop={true}
                />
              </div>
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
                <b className="self-stretch relative leading-[26px] inline-block">
                  Fast paced
                </b>
                <div className="self-stretch relative text-base leading-[20px] font-plus-jakarta-sans text-gray-300 inline-block">
                  Look, I may not be a genius in algorithms. But{" "}
                  <b>I absolutely thrive in a fast paced environment</b>.
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
              <div className="bg-[#f5f5f7] w-[64px] h-[64px] rounded-3xl flex justify-center items-center">
                <Lottie
                  style={{ height: 48, width: 48 }}
                  animationData={ActivityAnimation}
                  loop={true}
                />
              </div>
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
                <b className="self-stretch relative leading-[26px] inline-block">
                  No micro management
                </b>
                <div className="self-stretch relative text-base leading-[20px] font-plus-jakarta-sans text-gray-300 inline-block">
                  I'm going to do my best to deliver as much value as possible
                  out of my role not because I have to, but because I want to do
                  stuff that I'm proud of.
                </div>
              </div>
            </div>
          </div>
          <div className="relative">
            <img
              className="relative rounded-[32px] w-full h-full max-w-[370px] max-h-[487px] shrink-0 overflow-hidden object-cover px-2"
              alt=""
              src="https://res.cloudinary.com/dldgxw9w6/image/upload/v1670593407/imgonline-com-ua-ReplaceColor-WGbPHh1G3HdviR_xcct6a.jpg"
            />
            <p className="pt-8 opacity-50 cursor-pointer" onClick={onOpen}>
              Psst, click here for a surprise
            </p>
            {renderModal()}
          </div>
          <div className="w-full max-w-[437px] shrink-0 flex flex-col p-5 xl:p-[0px_104px_0px_64px] box-border items-end justify-center gap-[48px]">
            <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
              <div className="bg-[#f5f5f7] w-[64px] h-[64px] rounded-3xl flex justify-center items-center">
                <Lottie
                  style={{ height: 48, width: 48 }}
                  animationData={NotificationAnimation}
                  loop={true}
                />
              </div>

              <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
                <b className="self-stretch relative leading-[26px] inline-block">
                  Great communication
                </b>
                <div className="self-stretch relative text-base leading-[20px] font-plus-jakarta-sans text-gray-300 inline-block">
                  We all know that communication is key, but certainly in a
                  remote world not much is possible without it.
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
              <div className="bg-[#f5f5f7] w-[64px] h-[64px] rounded-3xl flex justify-center items-center">
                <Lottie
                  style={{ height: "100%", width: "100%", fill: "#f5f5f7" }}
                  animationData={FavouriteAnimation}
                  loop={true}
                />
              </div>
              <div className="self-stretch flex flex-col items-center justify-start gap-[8px]">
                <b className="self-stretch relative leading-[26px] inline-block">
                  Obsession & Passion
                </b>
                <div className="self-stretch relative text-base leading-[20px] font-plus-jakarta-sans text-gray-300 inline-block">
                  I'm literally obsessed about self-development and that's one
                  of my main character traits. Why? Because I can never stay in
                  place without always improving on myself.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
