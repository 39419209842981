import { FunctionComponent } from "react";
import { Avatar } from "@chakra-ui/react";
import { motion } from "framer-motion";

const Testimonial: FunctionComponent = () => {
  return (
    <motion.div
      style={{
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 6px",
      }}
      whileHover={{
        boxShadow: "rgba(0, 0, 0, 0.10) 0px 0px 0px 8px",
        transform: "translateY(-15px)",
      }}
      onClick={() =>
        window.open(
          "https://www.linkedin.com/in/covidiu97/details/recommendations/",
          "_blank"
        )
      }
      className="testimonial max-w-7xl relative mx-auto px-5 py-10 xl:p-24 bg-white rounded-[20px] cursor-pointer flex flex-col justify-start xl:justify-center items-start xl:items-center gap-8 font-plus-jakarta-sans"
    >
      <img src="../logos/easysize-logo.svg" width={100} />
      <h2 className="text-left xl:text-center text-sm lg:text-lg max-w-3xl">
        Ovi's mastery of both his core role and the projects that extend beyond
        it has been critical to the Shopify app growth.
        <br />
        <br />
        Above all, I was impressed with his ability to{" "}
        <span className="font-semibold">communicate effectively</span> and get
        things done with excellent quality. This rare mix of productivity and{" "}
        <span className="font-semibold">fast delivering results</span> sets a
        great example and explains why everyone loves working with Ovi -- no
        matter where they fall on the org chart.
        <br />
        <br />
        As a bonus, he has a great sense of humor! Therefore, if you want to
        work with a great person and a great professional look no further.
      </h2>
      <div className="flex flex-col items-start xl:items-center gap-1.5">
        <Avatar
          className="hidden lg:inline-flex"
          src="https://media-exp1.licdn.com/dms/image/C4D03AQHep0jM6-3ZkQ/profile-displayphoto-shrink_100_100/0/1573209070464?e=1670457600&v=beta&t=vphcSBNr_tyQql5zE2iutQ6mVisw3F4IGKHkez5Chic"
        />
        <span className="text-md font-semibold">Thelma Kalentzoti</span>
        <span className="text-sm opacity-60 font-semibold">
          Head of Customer Success @ Easysize
        </span>
      </div>
    </motion.div>
  );
};

export default Testimonial;
