import React, { FunctionComponent, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Tag, UnorderedList } from "@chakra-ui/react";
import Marquee from "./Marquee";

const Projects: FunctionComponent = () => {
  const renderTrustBadge = () => (
    <div className="bg-[#fafafc] rounded-[32px]  max-w-[1540px] w-full  flex flex-col p-[64px_0px] box-border items-center justify-start relative gap-[64px] text-left text-lg">
      <b className="relative leading-[1px] inline-block z-[0]">
        Apps being used by 1,000+ stores
      </b>
      <Marquee
        className="max-w-[1540px] w-full flex flex-row p-[0px_32px] box-border items-center justify-between z-[1]"
        direction="right"
        speed={100}
        gradient={false}
      >
        <img
          className="mx-5 w-full h-[24px] "
          alt=""
          src="../logos/logo-walmart.svg"
        />
        <img
          className="mx-5 w-full h-[24px] "
          alt=""
          src="../logos/logo-f64.svg"
        />
        <img
          className="mx-5 w-full h-[24px] "
          alt=""
          src="../logos/logo-elefant.svg"
        />
        <img
          className="mx-5 w-full h-[24px] "
          alt=""
          src="../logos/logo-floria.svg"
        />
        <img
          className="mx-5 w-full h-[24px] "
          alt=""
          src="../logos/logo-miniprix.svg"
        />
        <img
          className="mx-5 w-full h-[24px] "
          alt=""
          src="../logos/logo-underarmour.svg"
        />
        <img
          className="mx-5 w-full h-[24px] "
          alt=""
          src="../logos/logo-carrefour.svg"
        />
      </Marquee>
    </div>
  );

  return (
    <div id="projects">
      <div className="xl:max-w-[1540px] xl:max-w-7xl md:max-w-6xl  mx-auto bg-[#fafafc] w-full flex flex-col px-5 py-[120px] box-border items-center justify-center gap-[24px] text-center text-5xl text-black font-grifter">
        <div className="mx-auto w-full max-w-7xl self-stretch text-left pb-[120px]">
          <div className="text-[34px] lg:text-6xl text-start flex items-start justify-start flex-col">
            <b>Projects I'm proud of</b>
            <b className="opacity-20">The most impactful, at least</b>
          </div>
        </div>
        <div className="w-full grid grid-cols-6 gap-3">
          <Card
            idx={0}
            title="Shopify Dashboard"
            size="large"
            background="radial-gradient(133.78% 103.78% at -7.31% -22%, #50d3ffa6 24.92%, #50d3ff1c 56.77%, #50d3ff 180%)"
            hoverShadow="rgba(80, 211, 255, 0.15) 0px 0px 0px 6px"
            initialShadow="rgba(80, 211, 255, 0.15) 0px 0px 0px 0px"
            videoSrc="https://res.cloudinary.com/dldgxw9w6/video/upload/v1666081539/Shopify_Dashboard___FIT_Quiz_w4goot_ud3crz.mp4"
          />
          <Card
            idx={1}
            size="small"
            // title="Summary"
            background="rgba(56, 189, 248, 0.4)"
            summaryPoints={{
              technologies: [
                {
                  title: "React",
                  bgColor: "rgba(56, 189, 248, 0.1)",
                  color: "#0284C7",
                },
                {
                  title: "Redux",
                  bgColor: "rgba(56, 189, 248, 0.1)",
                  color: "#0284C7",
                },
                {
                  title: "Highcharts",
                  bgColor: "rgba(56, 189, 248, 0.1)",
                  color: "#0284C7",
                },
                {
                  title: "Redash",
                  bgColor: "rgba(56, 189, 248, 0.1)",
                  color: "#0284C7",
                },
                {
                  title: "Polaris",
                  bgColor: "rgba(56, 189, 248, 0.1)",
                  color: "#0284C7",
                },
              ],
              responsibilities:
                "Implementation of the front end + hooking up with redash API through a REST API",
              challenges:
                "Recreating the micro-interactions and aggregating the daily data to a monthly basis without any additional API calls while maintaining 0 loading times",
            }}
          />
          <Card
            idx={2}
            summaryPoints={{
              technologies: [
                {
                  title: "React",
                  bgColor: "#E6E5FA",
                  color: "#4902C7",
                },
                {
                  title: "Redux",
                  bgColor: "#E6E5FA",
                  color: "#4902C7",
                },
                {
                  title: "Polaris",
                  bgColor: "#E6E5FA",
                  color: "#4902C7",
                },
                {
                  title: "Django",
                  bgColor: "#E6E5FA",
                  color: "#4902C7",
                },
                {
                  title: "Python",
                  bgColor: "#E6E5FA",
                  color: "#4902C7",
                },
                {
                  title: "GA4",
                  bgColor: "#E6E5FA",
                  color: "#4902C7",
                },
              ],
              responsibilities:
                "End to end development. Went from 7 total steps in the onboarding down to 4, planning on eliminating two more in the future.",
              challenges:
                // "First challenge was not having enough clarity from a product's perspective. The first version of the app had 7 steps and was way too complicated for an user. Ended cleaning up and refactoring 60% of the app as the product had to to be adjusted. The second technical challenge was figuring out an algorithm to somehow scan the Shopify's product page's html to get a class selector in which we can automatically inject our code (instead of asking the user to)",
                "One technical challenge was figuring out an algorithm to somehow scan the Shopify's product page's html to get a class selector in which we can automatically inject our code",
            }}
            size="small"
          />
          <Card
            idx={3}
            title="Fitquiz app onboarding"
            size="large"
            background="radial-gradient(133.78% 103.78% at -7.31% -22%, #4902c76b 24.92%, #4902C71c 56.77%, #4902C7 180%)"
            hoverShadow="rgba(153, 0, 255, 0.15) 0px 0px 0px 6px"
            initialShadow="rgba(153, 0, 255, 0.15) 0px 0px 0px 0px"
            videoSrc="https://res.cloudinary.com/dldgxw9w6/video/upload/v1666081539/Onboarding_oemrj9_oasgcc.mp4"
          />
          <Card
            idx={4}
            title="Wishlist"
            size="large"
            background="radial-gradient(133.78% 103.78% at -7.31% -22%, #c7550254 24.92%, #c755021c 56.77%, #c75502 180%)"
            hoverShadow="rgba(253, 119, 2, 0.15) 0px 0px 0px 6px"
            initialShadow="rgba(253, 119, 2, 0.15) 0px 0px 0px 0px"
            videoSrc="https://res.cloudinary.com/dldgxw9w6/video/upload/v1666081539/Wishlist_-_F64_cpamdx_st6zwq.mp4"
          />
          <Card
            idx={5}
            summaryPoints={{
              technologies: [
                {
                  title: "React",
                  bgColor: "rgba(247, 130, 57, 0.1)",
                  color: "#C75502",
                },
                {
                  title: "Redux",
                  bgColor: "rgba(247, 130, 57, 0.1)",
                  color: "#C75502",
                },
                {
                  title: "Node.js",
                  bgColor: "rgba(247, 130, 57, 0.1)",
                  color: "#C75502",
                },
                {
                  title: "TypeScript",
                  bgColor: "rgba(247, 130, 57, 0.1)",
                  color: "#C75502",
                },
                {
                  title: "VTEX IO",
                  bgColor: "rgba(247, 130, 57, 0.1)",
                  color: "#C75502",
                },
              ],
              responsibilities:
                "End to end architecture, implementation & design within the VTEX ecosystem.",
              challenges:
                "The VTEX ecosystem has created lots of drawbacks since we didn't have full control and certain small, but important features had to be adjusted. Not having Redux in a complex ecom store was a huge drawback as well.",
            }}
          />
          {/*TODO: Film the right video from the 5 biggest ecom shops*/}
          {/*<Card*/}
          {/*  idx={6}*/}
          {/*  size="extra-large"*/}
          {/*  title="Numerous apps running on 1,000+ stores"*/}
          {/*  className="col-span-6"*/}
          {/*  background="radial-gradient(133.78% 103.78% at -7.31% -22%, #f5f5f7 24.92%, #f5f5f7 56.77%, #f5f5f7 180%)"*/}
          {/*  hoverShadow="rgba(0, 0, 0, 0.05) 0px 0px 0px 6px"*/}
          {/*  initialShadow="rgba(0, 0, 0, 0.05) 0px 0px 0px 0px"*/}
          {/*  videoSrc="https://res.cloudinary.com/dldgxw9w6/video/upload/v1664720474/Wishlist_-_F64_cpamdx.mp4"*/}
          {/*/>*/}
        </div>

        {renderTrustBadge()}
      </div>
    </div>
  );
};

const Card = ({
  size = "small",
  className = "",
  title = null,
  background = "",
  initialShadow = "",
  hoverShadow = "",
  videoSrc = "",
  fontColor = "",
  summaryPoints = null,
  idx,
}) => {
  const [hovering, setHovering] = useState(false);
  const [cardSize, setCardSize] = useState<string>();

  useEffect(() => {
    switch (size) {
      case "small":
        setCardSize("col-span-6 lg:col-span-2");
        break;
      case "large":
        setCardSize("col-span-6 lg:col-span-4");
        break;
      case "extra-large":
        setCardSize("col-span-6 lg:col-span-6");
    }
  }, [size]);

  return (
    <motion.div
      initial={{ boxShadow: initialShadow }}
      whileHover={{
        boxShadow: hoverShadow,
      }}
      key={idx}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      className={`rounded-[20px] h-[605px] cursor-pointer relative ${cardSize} ${className}`}
      style={!summaryPoints ? { background } : { background: "#fff" }}
    >
      <div className="w-full h-full relative">
        <motion.h3
          animate={size !== "small" ? (hovering ? "play" : "pause") : ""}
          variants={{
            play: { opacity: 0, y: -50, transition: { duration: 0.1 } },
            pause: { opacity: 1, y: 0 },
          }}
          className={`w-full pt-12 text-center absolute text-[${fontColor}] text-3xl xl:text-4xl z-10 pointer-events-none`}
        >
          {title}
        </motion.h3>
        {summaryPoints ? (
          <div className="flex flex-col justify-start text-left py-12 px-8 text-black overflow-auto">
            <h3 className="text-3xl pb-5 tracking-[1px]">Challenges</h3>
            <p className="font-plus-jakarta-sans text-left text-[#808080] leading-6 text-sm xl:text-base font-semibold tracking-wide pb-8">
              {summaryPoints.challenges}
            </p>
            <h3 className="text-3xl pb-5 tracking-[1px]">Responsibilities</h3>
            <p className="font-plus-jakarta-sans text-left text-[#808080] leading-6 text-sm xl:text-base font-semibold tracking-wide pb-8">
              {summaryPoints.responsibilities}
            </p>
            <h3 className="text-3xl pb-5 tracking-[1px]">Tech stack</h3>
            <UnorderedList className="font-plus-jakarta-sans text-left flex flex-row flex-wrap ml-[-8px]">
              {summaryPoints.technologies?.map(
                ({ title, bgColor, color }, idx) => (
                  <Tag
                    key={idx + title}
                    className="m-1.5 px-2 xl:px-5 py-1.5 xl:py-2 rounded-[12px] text-sm xl:text-base font-semibold"
                    size={"lg"}
                    bgColor={bgColor}
                    color={color}
                  >
                    {title}
                  </Tag>
                )
              )}
            </UnorderedList>
          </div>
        ) : null}
        {videoSrc ? (
          <motion.div
            animate={hovering ? "play" : "pause"}
            className="flex justify-center items-center object-cover w-full h-full overflow-hidden"
          >
            <motion.video
              initial={{ opacity: 0.6 }}
              variants={{
                pause: { opacity: 0.6, scale: 0.9, y: 100 },
                play: { opacity: 1, scale: 1, y: 0 },
              }}
              onMouseEnter={(e) => {
                e.currentTarget.playbackRate = 1.8;
                e.currentTarget.play();
              }}
              onMouseLeave={(e) => e.currentTarget.pause()}
              onClick={(e) => {
                e.currentTarget.currentTime = 0;
              }}
              loop
              muted
              playsInline
              preload="metadata"
              crossOrigin="anonymous"
              disablePictureInPicture
              className="object-cover w-[95%] h-[95%] block rounded-[20px]"
            >
              <source src={videoSrc} />
            </motion.video>
          </motion.div>
        ) : null}
        {/*{summaryPoints ? (*/}
        {/*  <IconButton*/}
        {/*    className={`absolute bottom-8 right-8 rounded-full w-16 h-16`}*/}
        {/*    style={{ background }}*/}
        {/*    onClick={() => setShowMore(!showMore)}*/}
        {/*    icon={!showMore ? <AddIcon /> : <CloseIcon />}*/}
        {/*    aria-label="show-more"*/}
        {/*  />*/}
        {/*) : null}*/}
      </div>
    </motion.div>
  );
};

export default Projects;
