import * as React from "react";
import { ReactNode, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Link,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import scrollToElement from "../utils/scrollToElement";
import {
  FaAngellist,
  FaGithub,
  FaGitlab,
  FaLinkedin,
  FaTwitterSquare,
} from "react-icons/fa";
import Lottie from "lottie-react";
import MenuAnimation from "../animations/hamburger.json";

export const INTERNAL_LINKS = [
  { selector: "#tech-stack", title: "Skills" },
  { selector: "#experience", title: "Experience" },
  { selector: "#projects", title: "Projects" },
  { selector: "#faq", title: "FAQ" },
];

export const SOCIAL_LINKS = [
  {
    icon: <FaLinkedin size={24} />,
    link: "https://www.linkedin.com/in/covidiu97/",
    title: "Linkedin",
  },
  {
    icon: <FaGithub size={24} />,
    link: "https://github.com/Ovio224",
    title: "Github",
  },
  {
    icon: <FaGitlab size={24} />,
    link: "https://gitlab.com/Ovio224",
    title: "Gitlab",
  },
  {
    icon: <FaAngellist size={24} />,
    link: "https://angel.co/u/oviolion",
    title: "AngelList",
  },
  {
    icon: <FaTwitterSquare size={24} />,
    link: "https://twitter.com/ovionut",
    title: "Twitter",
  },
];

export const NavLink = ({
  children,
  selector,
  darkMode,
  className = "",
}: {
  children: ReactNode;
  selector: string;
  darkMode: boolean;
  className?: string;
}) => (
  <Link
    px={2}
    py={1}
    className={`${className} relative group w-full flex justify-center `}
    color={darkMode ? "white" : "black"}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: darkMode ? "whiteAlpha.300" : "blackAlpha.200",
    }}
    onClick={() => scrollToElement(selector, "start", "nearest")}
  >
    <div className="relative overflow-hidden group w-full flex justify-center px-2 font-bold">
      <span className="invisible">{children}</span>
      <span className="absolute top-0 group-hover:-translate-y-full transition-transform ease-in-out duration-300 hover:duration-300">
        {children}
      </span>
      <span className="absolute top-0 translate-y-full group-hover:translate-y-0 transition-transform ease-in-out duration-300 hover:duration-300">
        {children}
      </span>
    </div>
  </Link>
);

export const SocialLink = ({ icon, title, link }) => (
  <IconButton
    size="md"
    fontSize="lg"
    variant="ghost"
    color="current"
    onClick={() => window.open(link, "_blank")}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: "whiteAlpha.300",
    }}
    icon={
      <div className="relative overflow-hidden group w-full flex justify-center px-2 font-bold">
        <span className="invisible">{icon}</span>
        <span className="absolute top-0 group-hover:-translate-y-full transition-transform ease-in-out duration-300 hover:duration-300">
          {icon}
        </span>
        <span className="absolute top-0 translate-y-full group-hover:translate-y-0 transition-transform ease-in-out duration-300 hover:duration-300">
          {icon}
        </span>
      </div>
    }
    aria-label={title}
  />
);

const MotionBox = motion(Box);

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [darkMode, setDarkMode] = useState(true);
  const animationRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      animationRef?.current?.setSpeed(1);
      animationRef?.current?.play();
    } else {
      animationRef?.current?.setSpeed(-1);
      animationRef?.current?.play();
    }
  }, [isOpen]);

  useEffect(() => {
    const onScroll = (e) => {
      if (window.scrollY > 0) {
        setDarkMode(false);
      } else {
        setDarkMode(true);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [darkMode]);

  return (
    <MotionBox
      animate={{
        backgroundColor: darkMode ? "black" : "#ffffffb3",
      }}
      className="z-20 sticky top-0 font-plus-jakarta-sans font-semibold transition-all duration-300"
      boxShadow="inset 0 -1px 0 0 #0000001a"
      backdropFilter="saturate(180%) blur(10px)"
      px={4}
    >
      <Flex
        className="max-w-7xl m-auto "
        h={16}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <IconButton
          size={"md"}
          icon={
            <Lottie
              lottieRef={animationRef}
              loop={false}
              autoplay={false}
              animationData={MenuAnimation}
            />
          }
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
        <Box
          onClick={() => scrollToElement("#hero", "start", "start")}
          className={`font-grifter text-3xl font-bold w-24 cursor-pointer flex ${
            darkMode ? "text-white" : "text-black"
          }`}
        >
          ovi<span className="opacity-30">diu</span>
          <span className="opacity-20">co</span>
        </Box>
        <HStack spacing={8} alignItems={"center"}>
          <HStack
            as={"nav"}
            spacing={12}
            display={{ base: "none", md: "flex" }}
          >
            {INTERNAL_LINKS.map(({ selector, title }) => (
              <NavLink darkMode={darkMode} key={title} selector={selector}>
                {title}
              </NavLink>
            ))}
          </HStack>
        </HStack>
        <Flex alignItems={"center"}>
          <Button
            variant="solid"
            bgColor={darkMode ? "white" : "black"}
            color={darkMode ? "black" : "white"}
            border={`1px solid ${darkMode ? "white" : "black"}`}
            className="relative overflow-hidden group w-full flex justify-center"
            as="a"
            href="mailto:ovidiuc224@gmail.com"
            _hover={
              darkMode
                ? {
                    backgroundColor: "whiteAlpha.300",
                    color: "white",
                  }
                : {
                    backgroundColor: "blackAlpha.700",
                    color: "white",
                  }
            }
            _active={
              darkMode
                ? {
                    backgroundColor: "blackAlpha.500",
                  }
                : {
                    backgroundColor: "blackAlpha.600",
                  }
            }
            fontSize={17}
          >
            <div className="relative overflow-hidden group w-full flex justify-center px-2 font-bold text-base xl:text-lgl">
              <span className="invisible">Get in touch</span>
              <span className="absolute top-0 group-hover:-translate-y-full transition-transform ease-in-out duration-300 hover:duration-300">
                Get in touch
              </span>
              <span className="absolute top-0 translate-y-full group-hover:translate-y-0 transition-transform ease-in-out duration-300 hover:duration-300">
                Get in touch
              </span>
            </div>
          </Button>
        </Flex>
      </Flex>

      {isOpen ? (
        <Box pb={4} display={{ md: "none" }}>
          <Stack as={"nav"} spacing={4}>
            {INTERNAL_LINKS.map(({ selector, title }) => (
              <NavLink darkMode={darkMode} key={title} selector={selector}>
                {title}
              </NavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </MotionBox>
  );
}
