import React, { CSSProperties, FunctionComponent } from "react";
import { Tag } from "@chakra-ui/react";
import { motion } from "framer-motion";
import scrollToElement from "../utils/scrollToElement";
import useElementOnScreen from "../hooks/useElementOnScreen";

const badgeContainerVariants = {
  open: {
    opacity: 1,
    transition: { staggerChildren: 0.04 },
  },
  closed: {
    opacity: 0,
    transition: { staggerChildren: 0.1, staggerDirection: -1 },
  },
};

const variants = {
  open: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    scale: 0.5,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const MotionTag = motion(Tag);

const Badge = ({
  title,
  bgColor = "#E6E5FA",
  color = "black",
  customStyle,
}) => {
  return (
    <MotionTag
      variants={variants}
      className="m-1.5 xl:m-3 px-2 xl:px-5 py-1.5 xl:py-2 rounded-[30px] text-sm xl:text-xl font-medium"
      size={"lg"}
      bgColor={bgColor}
      color={color}
      style={customStyle}
    >
      {title}
    </MotionTag>
  );
};

const SequenceScroll = () => {
  const refs = [
    useElementOnScreen({
      root: null,
      rootMargin: "-50% 0% -50% 0%",
      threshold: 0,
    }),

    useElementOnScreen({
      root: null,
      rootMargin: "-50% 0% -50% 0%",
      threshold: 0,
    }),

    useElementOnScreen({
      root: null,
      rootMargin: "-50% 0% -50% 0%",
      threshold: 0,
    }),

    useElementOnScreen({
      root: null,
      rootMargin: "-50% 0% -50% 0%",
      threshold: 0,
    }),
  ];

  return (
    <div className="tech-stack-wrapper">
      <div
        className="first:pt-10 xl:first:pt-[50vh]"
        style={{ transition: "all 2s" }}
      >
        {TOPICS.map(({ title }, idx) => {
          const [containerRef, isVisible] = refs[idx];
          return (
            <h3
              // @ts-ignore
              ref={containerRef}
              key={idx}
              className={`${isVisible ? "opacity-100" : "opacity-10"} ${
                !isVisible && "hover:opacity-25"
              } xl:py-9 text-[28px] xl:text-[50px] text-left font-grifter transition-all duration-300  cursor-pointer select-none`}
            >
              <br />
              <br />
              {title}
            </h3>
          );
        })}
      </div>

      <div className="topic-card-wrapper">
        <motion.div
          className="tech-card-wrapper hidden lg:inline-flex transition-all duration-300 h-[305px] w-[305px] xl:h-[688px] xl:w-[588px] z-10 relative rounded-[30px]"
          style={{
            display: refs.filter(([ref, isVisible]) => isVisible).length
              ? "none"
              : "",
            position: "relative",
            background: "#f5f5f7",
          }}
        />
        {TOPICS.map((topic, idx) => {
          const [ref, isCurrentVisible] = refs[idx];
          return (
            <motion.div
              variants={{
                open: {
                  opacity: 1,
                },
                closed: {
                  opacity: 0.5,
                },
              }}
              key={idx}
              transition={{ type: "spring", stiffness: 100 }}
              animate={isCurrentVisible ? "open" : "closed"}
              className="h-[305px] w-full xl:h-[688px] xl:w-[588px] z-10 relative rounded-[30px] overflow-hidden tech-card-wrapper"
              style={{
                display: isCurrentVisible ? "" : "none",
                position: "relative",
                background: isCurrentVisible ? topic.background : "#f5f5f7",
              }}
            >
              {topic.badgeList?.length ? (
                <motion.ul
                  className="flex flex-wrap justify-center content-center items-center w-full h-full p-2 xl:p-[6rem]"
                  variants={badgeContainerVariants}
                  animate={isCurrentVisible ? "open" : "closed"}
                >
                  {topic.badgeList?.map(
                    ({ title, bgColor, color, customStyle }, idx) => (
                      <Badge
                        key={`${title}-${bgColor}-${color}-${idx}`}
                        customStyle={customStyle}
                        bgColor={bgColor}
                        title={title}
                        color={color}
                      />
                    )
                  )}
                </motion.ul>
              ) : null}
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

const TechStack: FunctionComponent = () => {
  return (
    <div
      id="tech-stack"
      className="relative max-w-7xl mx-auto bg-[#fafafc] pt-20 px-5 lg:pt-60 items-center justify-start gap-[64px] text-center text-[34px] lg:text-6xl text-black"
    >
      <div className="w-full self-stretch xl:pb-[120px] font-grifter">
        <div>
          <b className="text-start flex items-start justify-start">
            What can I bring to the table?
          </b>
          <b className="text-start flex items-start justify-start opacity-20">
            Tech I know & love to use
          </b>
        </div>
      </div>
      <SequenceScroll />
    </div>
  );
};

interface ITechBadge {
  title: string;
  bgColor: string;
  color: string;
  customStyle?: CSSProperties;
}

const LOVE_WORK_BADGE_LIST: ITechBadge[] = [
  {
    title: "Functional programming",
    bgColor: "rgba(56, 189, 248, 0.1)",
    color: "#0284C7",
  },
  {
    title: "React",
    bgColor: "rgba(56, 189, 248, 0.1)",
    color: "#0284C7",
  },
  {
    title: "Redux",
    bgColor: "rgba(56, 189, 248, 0.1)",
    color: "#0284C7",
  },
  {
    title: "Typescript",
    bgColor: "rgba(56, 189, 248, 0.1)",
    color: "#0284C7",
  },
  {
    title: "Dapps",
    bgColor: "rgba(56, 189, 248, 0.1)",
    color: "#0284C7",
  },
  {
    title: "Figma",
    bgColor: "rgba(56, 189, 248, 0.1)",
    color: "#0284C7",
  },
  {
    title: "CSS",
    bgColor: "rgba(56, 189, 248, 0.1)",
    color: "#0284C7",
  },
  {
    title: "Node.js",
    bgColor: "rgba(56, 189, 248, 0.1)",
    color: "#0284C7",
  },
  {
    title: "PostgreSQL",
    bgColor: "rgba(56, 189, 248, 0.1)",
    color: "#0284C7",
  },

  {
    title: "Kotlin",
    bgColor: "rgba(56, 189, 248, 0.1)",
    color: "#0284C7",
  },
  {
    title: "Framer motion",
    bgColor: "rgba(56, 189, 248, 0.1)",
    color: "#0284C7",
  },
  {
    title: "GSAP",
    bgColor: "rgba(56, 189, 248, 0.1)",
    color: "#0284C7",
  },
  {
    title: "Beautiful designs",
    bgColor: "rgba(56, 189, 248, 0.1)",
    color: "#0284C7",
  },
  {
    title: "Gradients",
    bgColor: "",
    color: "#fff",
    customStyle: {
      background: "url(../svg/Aurora.svg)",
      backgroundSize: "cover",
    },
  },
  {
    title: "Pretty much all styleguides out there",
    bgColor: "rgba(56, 189, 248, 0.1)",
    color: "#0284C7",
  },
];
const EXPERIENCE_BADGE_LIST: ITechBadge[] = [
  {
    title: "Java",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "Kotlin",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "Next.js",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "GraphQL",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "Django",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "Python",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "Spring boot",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "MySQL",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "MongoDB",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "Vercel",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "Templating engines",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "OOP",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "Express.js",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "HTML",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "Git",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
  {
    title: "Ecommerce",
    bgColor: "rgba(247, 130, 57, 0.1)",
    color: "#C75502",
  },
];
const LEARN_NEXT_BADGE_LIST: ITechBadge[] = [
  {
    title: "React Native",
    bgColor: "#E6E5FA",
    color: "#4902C7",
  },
  {
    title: "Flutter",
    bgColor: "#E6E5FA",
    color: "#4902C7",
  },
  {
    title: "Swift",
    bgColor: "#E6E5FA",
    color: "#4902C7",
  },
  {
    title: "Solidity/Rust",
    bgColor: "#E6E5FA",
    color: "#4902C7",
  },
  {
    title: "Mobile development",
    bgColor: "#E6E5FA",
    color: "#4902C7",
  },
  {
    title: "Design oriented approach",
    bgColor: "#E6E5FA",
    color: "#4902C7",
  },
];
const HATE_BADGE_LIST: ITechBadge[] = [
  {
    title: "Angular",
    bgColor: "rgba(247, 57, 114, 0.1)",
    color: "#C70236",
  },
];

const TOPICS = [
  {
    background:
      'url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"), radial-gradient(133.78% 103.78% at -7.31% -22%, #7a50ff00 0%, #bebbff00 0%, #00b4ff13 47.92%, #2c69b100 56.77%, #86ebd900 75%, #50d3ff59 180%)',
    badgeList: LOVE_WORK_BADGE_LIST,
    title: (
      <span id="love" onClick={() => scrollToElement("#love")}>
        What I've <span className="text-[#0284C796]">loved</span> working with
        so far
      </span>
    ),
  },
  {
    background:
      "url(https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png), radial-gradient(133.78% 103.78% at -7.31% -22%, #7a50ff00 0%, #bebbff00 0%, #9e6d0013 47.92%, #2c9eb100 56.77%, #86ebd900 75%, #ff9d5059 180%)",
    badgeList: EXPERIENCE_BADGE_LIST,
    title: (
      <span id="competences" onClick={() => scrollToElement("#competences")}>
        What I've got <span className="text-[#C7550296]">experience</span> with
      </span>
    ),
  },
  {
    background:
      'url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"), radial-gradient(133.78% 103.78% at -7.31% -22%, #7a50ff00 0%, #bebbff00 0%, #00789e13 47.92%, #2c9eb100 56.77%, #86ebd900 75%, #7a50ff59 180%)',
    badgeList: LEARN_NEXT_BADGE_LIST,
    title: (
      <span id="learn" onClick={() => scrollToElement("#learn")}>
        What I'd love to <span className="text-[#4902C796]">learn</span> next
      </span>
    ),
  },
  {
    background:
      'url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"), radial-gradient(133.78% 103.78% at -7.31% -22%, #7a50ff00 0%, #bebbff00 0%, #ff000013 47.92%, #b12c2c00 56.77%, #eb868600 75%, #ff505059 180%)',
    badgeList: HATE_BADGE_LIST,
    title: (
      <div className="flex flex-col items-start">
        <Tag bgColor="#e2e2e2" className="mb-3 p-2 text-md">
          SORRY NOT SORRY
        </Tag>
        <span id="hate" onClick={() => scrollToElement("#hate")}>
          What I <del className="text-[#C7023696]">hate</del> dislike
        </span>
      </div>
    ),
  },
];

export default TechStack;
