import React, { FunctionComponent, useEffect } from "react";
import Footer from "../Components/Footer";
import Experience from "../Components/Experience";
import TechStack from "../Components/TechStack";
import Projects from "../Components/Projects";
import Hero from "../Components/Hero";
import Navbar from "../Components/Navbar";
import FAQ from "../Components/Faq";

const Portfolio: FunctionComponent = () => {
  useEffect(() => {
    console.log(
      "%cHey what's up? It's a bummer that you got to see the warnings I have successfully ignored above 👀",
      'color: WHITE; background: dark-grey; font-size: 40px; font-family: "GRIFTER"'
    );
    console.log(
      "%cBy the way if you've found any bug in here you could drop me a message anywhere you'd like. I got my 👀 on twitter pretty often",
      'color: WHITE; background: dark-grey; font-size: 21px; font-family: "GRIFTER"'
    );
  }, []);

  return (
    <div id="top">
      <main data-scroll-container className="bg-[#fafafc] .once-in">
        <Loading />
        <Navbar />
        <Hero />
        <TechStack />
        <Experience />
        <Projects />
        <FAQ />
      </main>
      <Footer />
    </div>
  );
};

const Loading = () => (
  <div className="loading-container">
    <div className="loading-screen" style={{ top: "calc(-100%)" }}>
      <div className="rounded-div-wrap top">
        <div className="rounded-div"></div>
      </div>
      <div
        className="loading-words"
        style={{ transform: "translate(0px, -50px)", opacity: 0 }}
      >
        <h2 className="home-active home-active-first text-2xl xl:text-6xl font-grifter">
          👋 Hi, I'm Ovi
        </h2>
      </div>
      <div className="rounded-div-wrap bottom" style={{ height: "0vh" }}>
        <div className="rounded-div"></div>
      </div>
    </div>
  </div>
);

export default Portfolio;
