import { FunctionComponent } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";

const QUESTIONS_AND_ANSWERS = [
  {
    question: "Who are you / what's your story?",
    answer: (
      <>
        <p>
          I'm Ovi, I just turned 25 at the time of writing this (4th of October)
        </p>
        <br />
        <p>
          I grew up loving computers ever since Santa got me one on my 7th b-day
          and as the regular nerd, I got into video-games.
        </p>
        <br />
        <p>
          I created some hacks for Minecraft and wrote some code to create
          plugins for my Minecraft server at 12yo (even though I had no idea
          what I was doing) and people said I was smart. Funny story, I wasn't.
          I just knew
          <a
            href="https://letmegooglethat.com/?q=how+to+google"
            className="text-[blue]"
            target="_blank"
          >
            {" "}
            how to google
          </a>{" "}
          stuff better than the average Joe
        </p>
        <br />
        <p>
          Of course I wanted to become a Software Engineer and so fast forward
          to 18 I went to college in Copenhagen, Denmark. That's when I got into
          self-development & coding and that's how I spent all my time learning,
          coding and meditating.
        </p>
        <br />
        <p>
          Did some volunteering & internships to learn more. Got my first real
          job. Had an <b>amazing mentor</b> and that's how I learned what true
          synergy is.
        </p>
        <br />
        <p>
          Got tangled with toxic managers. Joined an amazing company in
          Copenhagen (remotely). The war with Russia & Ukraine affected the
          company.
        </p>
        <br />
        <p>And that leads us here. </p>
        <br />
        <p>
          I'm a video game nerd on a journey to create stuff that I'm proud of,
          overly excited about developing jaw-dropping interfaces, and got a
          deep obsession for self-development.
        </p>
      </>
    ),
  },
  {
    question: "Where are you based?",
    answer: `Simple answer, I'm from Romania and currently living in my hometown, Pitesti. I've lived in plenty places and still find myself coming back to my hometown. Although I'd be willing to relocate or to monthly travel for meetings / events for the right company`,
  },
  {
    question: "How do you spell your name?",
    answer: (
      <span>
        As much as I love how funny it is when people are trying to spell my
        name (I've heard every version of it lol) people have never called me
        anything else, but <b>Ovi</b>
      </span>
    ),
  },
  {
    question: "Are you willing to relocate?",
    answer:
      "The thing is I still find myself coming back to Romania and so for an awesome team and for a short-term (~12 months) I'd love to, but I don't see it working as a long-term solution.",
  },
  {
    question: "When can you start?",
    answer:
      "Pretty much as soon as possible, but we can surely discuss those details further.",
  },
  {
    question: "What are your salary expectations?",
    answer: (
      <>
        <p>As much as people avoid talking about this topic, I don't.</p>
        <br />
        <p>
          I believe in transparency and for this reason in the past 3 years I've
          been compensated somewhere within the range of $25/h - $41/h and
          because of that, I'm looking to stay within this range.
        </p>
      </>
    ),
  },
  {
    question: "What's the perfect role for you?",
    answer: (
      <>
        <p>
          I've been working with so many tech stacks during my career, but two
          things have never left the table: <b>React & Typescript</b> and I'd
          like to continue using them
        </p>
        <br />
        <p>
          And besides that, I'm 100% confident that I can learn literally any
          tech stack which doesn't include <del>Angular</del> in a relatively
          short time
        </p>
      </>
    ),
  },
  {
    question: "How does a good company culture look like to you?",
    answer: (
      <>
        <p>
          Over the years I've figured a couple of things that I love to see in a
          company:
        </p>
        <br />
        <br />
        <ul className="ml-6">
          <li>Great communication</li>
          <li>A passionate team to be a part of</li>
          <li>No toxicity amongst the leadership</li>
          <li>A company with a clear mission</li>
        </ul>
      </>
    ),
  },
];

const FAQ: FunctionComponent = () => {
  return (
    <div
      id="faq"
      className="relative bg-[#fafafc] flex flex-col px-5 py-24 xl:p-[120px_0px] box-border items-center justify-start gap-[64px] text-center text-5xl text-black font-grifter min-h-[1080px]"
    >
      <b className="relative leading-[1px] flex items-center justify-center max-w-7xl w-full h-[64px] shrink-0">
        FAQs
      </b>
      <FaqItem questionArray={QUESTIONS_AND_ANSWERS} />
    </div>
  );
};

interface FaqItemProps {
  questionArray: { question: string; answer: any }[];
}
const FaqItem = (props: FaqItemProps) => (
  <Accordion className="max-w-3xl w-full" allowToggle>
    {props?.questionArray?.map(({ question, answer }, idx) => (
      <div
        key={idx}
        className="gradient-shadow gradient-shadow-blur-sm transition-all relative rounded-[md_!important] hover:scale-100 w-[100%_!important] h-[100%_!important] "
      >
        <AccordionItem className="bg-[#f4f6f9] border-none mb-4 rounded-md">
          <h2>
            <AccordionButton className="py-5 px-5">
              <Box
                className="text-lg xl:text-xl font-plus-jakarta-sans font-bold"
                flex="1"
                textAlign="left"
              >
                {question}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel
            className="py-4 px-4 pt-2 font-plus-jakarta-sans text-base xl:text-xl text-left leading-[140%] text-gray-600"
            pb={4}
          >
            {answer}
          </AccordionPanel>
        </AccordionItem>
      </div>
    ))}
  </Accordion>
);

export default FAQ;
