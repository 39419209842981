import React, { FunctionComponent } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Testimonial from "./Testimonial";
import { Tag } from "@chakra-ui/react";

const Experience: FunctionComponent = () => {
  const renderExperiencePoint = ({
                                   date,
                                   role,
                                   company,
                                   description,
                                   region,
                                   iconSrc,
                                   techStack = []
                                 }) => (
    <VerticalTimelineElement
      className={`vertical-timeline-element--education font-plus-jakarta-sans ${company} opacity-100 md:opacity-50`}
      iconClassName="rounded-full shadow-none"
      icon={
        <img
          className="rounded-full border-[3px] border-[#ebebeb] border-solid"
          src={iconSrc}
          alt={`${company} logo`}
        />
      }
    >
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1">
          <h3 className="vertical-timeline-element-title text-lg font-bold">
            {role}
          </h3>
          <h4 className="vertical-timeline-element-subtitle text-sm opacity-90">
            {company}
          </h4>
          <span className="text-sm opacity-70">{date}</span>
          <span className="text-sm opacity-70">{region}</span>
        </div>
        <span className="text-md max-w-2xl">{description}</span>
      </div>
      <div className="mt-6 flex flex-row flex-wrap mb-[-16px]">
        {techStack?.map(({ skill, bgColor }) => (
          <Tag
            key={skill}
            className="m-1 px-3 py-2 rounded-[30px] text-sm"
            size="sm"
            fontWeight={500}
            bgColor={bgColor}
          >
            {skill}
          </Tag>
        ))}
      </div>
    </VerticalTimelineElement>
  );

  return (
    <div id="experience" className="p-5">
      <div
        className="mx-auto w-full max-w-7xl self-stretch pb-14 xl:pb-[120px] font-grifter text-[34px] lg:text-6xl flex-col text-start flex items-center justify-center">
        <b className="">My career journey</b>
        <b className="opacity-20">And the skills I've learned</b>
      </div>
      <div className="pb-[120px]">
        <Testimonial />
      </div>

      <VerticalTimeline
        layout="1-column-left"
        animate={false}
        className="max-w-7xl"
      >
        <div className="line w-[3px] h-[0%] bg-[#C8C6C6] absolute top-0 left-[18px]"></div>
        {renderExperiencePoint({
          role: "Frontend Engineer",
          company: "Nexoya",
          date: "Feb 2022 - Present · 1 yr",
          region: "Remote, Zurich, Switzerland",
          description: (
            <ul className="md:ml-5 leading-6 list-none md:list-disc">
              <li>
                Implemented multiple visual charts from scratch including a multi-series funnel
              </li>
              <li>Increased the performance of certain API's and of the Frontend by 90%</li>
              <li>
                Redesigned the navigation system
              </li>
              <li>Revamped the tables to a more modern UI</li>
              <li>
                Designed and implemented a new onboarding flow
              </li>
              <li>
                Designed and implemented new screens
              </li>
            </ul>
          ),
          iconSrc:
            "https://res.cloudinary.com/dmuoy9c9y/image/upload/v1704960947/f04lakwatuqicqtmxvn4.jpg",
          techStack: [
            { skill: "React", bgColor: "#0EC76A4D" },
            { skill: "GraphQL", bgColor: "#0EC76A4D" },
            { skill: "MySQL", bgColor: "#0EC76A4D" },
            { skill: "NodeJS", bgColor: "#0EC76A4D" },
            { skill: "Typescript", bgColor: "#0EC76A4D" },
            { skill: "Apollo", bgColor: "#0EC76A4D" },
            { skill: "Amcharts4", bgColor: "#0EC76A4D" },
            { skill: "Styled-components", bgColor: "#0EC76A4D" }
          ]
        })}
        {renderExperiencePoint({
          role: "Full Stack Developer",
          company: "Easysize",
          date: "Jun 2021 - Dec 2022 · 1 yr 7 mos",
          region: "Remote, Copenhagen",
          description: (
            <ul className="md:ml-5 leading-6 list-none md:list-disc">
              <li>
                Solely created a self-service solution as a Shopify app from
                scratch
              </li>
              <li>Replicated Shopify's dashboard within our app</li>
              <li>
                Successfully launched the app in the Shopify App Store with over
                500 installs
              </li>
              <li>Held interviews with tech candidates</li>
              <li>
                Continuously improved user flow to increase user conversion to a
                premium plan
              </li>
            </ul>
          ),
          iconSrc:
            "https://res.cloudinary.com/dldgxw9w6/image/upload/w_50,f_auto/v1664904361/1574348402523_pxci0f.jpg",
          techStack: [
            { skill: "Python", bgColor: "#ffc3ca66" },
            { skill: "Django", bgColor: "#ffc3ca66" },
            { skill: "PostgreSQL", bgColor: "#ffc3ca66" },
            { skill: "React", bgColor: "#ffc3ca66" },
            { skill: "Typescript", bgColor: "#ffc3ca66" },
            { skill: "Redux", bgColor: "#ffc3ca66" },
            { skill: "Liquid", bgColor: "#ffc3ca66" },
            { skill: "ES6", bgColor: "#ffc3ca66" }
          ]
        })}
        {renderExperiencePoint({
          role: "Full Stack Developer",
          company: "VTEX",
          date: "May 2020 - Jun 2021 · 1 yr 2 mos",
          region: "Remote",
          description: (
            <ul className="md:ml-5 leading-6 list-none md:list-disc">
              <li>
                Refactor legacy microservices in Node.js/React/Typescript and
                within the VTEX store framework
              </li>
              <li>
                Created standalone public apps for e-commerce needs which are
                now being used by <span className="font-semibold">1,000+</span>{" "}
                e-commerce shops such as but not limited by:
                <br />
                <br />
                • Wishlist
                <br />
                • Returns System
                <br />
                • Loyalty System
                <br />
                • Refurbished System
                <br />
                • Dynamic bundles
                <br />
                • Price history
                <br />
              </li>
            </ul>
          ),
          iconSrc:
            "https://res.cloudinary.com/dldgxw9w6/image/upload/w_50,f_auto/v1664894880/1626116372683_l6lxfk.jpg",
          techStack: [
            { skill: "Node.js", bgColor: "#f845811a" },
            { skill: "Typescript", bgColor: "#f845811a" },
            { skill: "Microservices", bgColor: "#f845811a" },
            { skill: "REST API", bgColor: "#f845811a" },
            { skill: "Redis", bgColor: "#f845811a" },
            { skill: "React", bgColor: "#f845811a" },
            { skill: "Redux", bgColor: "#f845811a" },
            { skill: "ES6", bgColor: "#f845811a" }
          ]
        })}
        {renderExperiencePoint({
          role: "Full Stack Developer",
          company: "Heineken",
          date: "Apr 2020 - May 2020 · 2 mos",
          region: "Remote",
          description: `Alongside a team of 4, managed to finish an estimated project of ~5-7 months in under 8 weeks in the contracts management platform of all HoReCa from Heineken in Romania`,
          iconSrc:
            "https://res.cloudinary.com/dldgxw9w6/image/upload/w_50,f_auto/v1664894761/heineken-logo-1_sxhiy0.png",
          techStack: [
            { skill: "Java", bgColor: "#26864633" },
            { skill: "Facelets", bgColor: "#26864633" },
            { skill: "MySQL", bgColor: "#26864633" }
          ]
        })}
        {renderExperiencePoint({
          role: "Junior Full Stack Developer",
          company: "Oraroo",
          date: "Jul 2019 - Apr 2020 · 10 mos",
          region: "Bucharest, Romania",
          description: (
            <ul className="leading-6">
              <span>
                While working for Oraroo, my main responsibilities were
                developing entire new features, fixing older technical debts,
                meeting with clients and improve the entire app.
              </span>
              <br />
              <br />
              <span>Some of the features:</span>
              <div className="md:ml-5">
                • email sending with custom email template
                <br />
                • personal/manager dashboard
                <br />• replicated the expense flow from{" "}
                <a
                  href="https://use.expensify.com/invoices"
                  className="text-[blue]"
                  target="_blank"
                >
                  Expensify
                </a>
                <br />
                • migrated the whole project from Bootstrap to Material UI
                <br />
              </div>
            </ul>
          ),
          iconSrc:
            "https://res.cloudinary.com/dldgxw9w6/image/upload/w_50,f_auto/v1664894417/1575576130238_n7elfn.jpg",
          techStack: [
            { skill: "Java", bgColor: "#9dea7833" },
            { skill: "Spring Boot", bgColor: "#9dea7833" },
            { skill: "Kotlin", bgColor: "#9dea7833" },
            { skill: "Express.js", bgColor: "#9dea7833" },
            { skill: "PostgreSQL", bgColor: "#9dea7833" },
            { skill: "React", bgColor: "#9dea7833" },
            { skill: "Redux", bgColor: "#9dea7833" },
            { skill: "Typescript", bgColor: "#9dea7833" },
            { skill: "Liquibase", bgColor: "#9dea7833" },
            { skill: "Camunda", bgColor: "#9dea7833" },
            { skill: "AWS", bgColor: "#9dea7833" }
          ]
        })}
        {renderExperiencePoint({
          role: "Full Stack Developer Intern",
          company: "Merittian",
          date: "Feb 2019 - Mar 2019 · 2 mos",
          region: "Copenhagen, Denmark",
          description: (
            <ul className="md:ml-5 leading-6 list-none md:list-disc">
              <li>
                Performed design, development and testing of features, primarily
                in JavaScript
              </li>
              <li>
                Solved both front-end and back-end tasks in React and
                respectively Node & Express
              </li>
              <li>Worked with the database environments in PostgreSQL.</li>
            </ul>
          ),
          iconSrc:
            "https://res.cloudinary.com/dldgxw9w6/image/upload/w_50,f_auto/v1664894327/1574701371453_agxh8h.jpg",
          techStack: [
            { skill: "Node.js", bgColor: "#f6d27166" },
            { skill: "PostgreSQL", bgColor: "#f6d27166" },
            { skill: "React", bgColor: "#f6d27166" },
            { skill: "Redux", bgColor: "#f6d27166" },
            { skill: "Redux Saga", bgColor: "#f6d27166" },
            { skill: "Typescript", bgColor: "#f6d27166" }
          ]
        })}
        {renderExperiencePoint({
          date: "Aug 2018 - Jan 2019 · 6 mos",
          role: "Frontend Developer Intern",
          company: "Ratoong",
          description: (
            <ul className="md:ml-5 leading-6 list-none md:list-disc">
              <li>
                Worked with Ionic, Firebase, Angular 4, TypeScript, JavaScript,
                HTML, SASS
              </li>
              <li>
                Have used those technologies to tweak the design and the web
                application's logic
              </li>
              <li>Have implemented SEO across the website</li>
            </ul>
          ),
          region: "Copenhagen, Denmark",
          iconSrc:
            "https://res.cloudinary.com/dldgxw9w6/image/upload/w_50,f_auto/v1664890057/1599757829719_kauygc.jpg",
          techStack: [
            { skill: "Angular 2", bgColor: "#a1d0d666" },
            { skill: "Typescript", bgColor: "#a1d0d666" },
            { skill: "Ionic", bgColor: "#a1d0d666" },
            { skill: "Firebase", bgColor: "#a1d0d666" },
            { skill: "SEO", bgColor: "#a1d0d666" }
          ]
        })}
        {renderExperiencePoint({
          role: "KEA – Copenhagen School of Design and Technology",
          company: "KEA",
          date: "Jan 2017 - Jul 2019",
          region: "Copenhagen, Denmark",
          description: (
            <ul className="md:ml-5 leading-6 list-none md:list-disc">
              <li>
                Developed an IoT project in Arduino which includes temperature,
                pressure, humidity sensors, with a team of 4 people in a
                competition, and won the first place for which we received a
                funding possibility to create and deploy the product to the
                market by a company the school was collaborating with
              </li>
              <li>
                Network design and assessing technical networking solutions in
                relation to the company and customer needs
              </li>
              <li>
                Servers - deployed several server infrastructure for a fictional
                company to production
              </li>
              <li>
                Over 5 real-world team-based projects using the latest
                management tools
              </li>
              <li>Over 25 individual projects</li>
            </ul>
          ),
          techStack: [
            { skill: "React", bgColor: "#f34e4c66" },
            { skill: "OOP", bgColor: "#f34e4c66" },
            { skill: "C#", bgColor: "#f34e4c66" },
            { skill: "Java", bgColor: "#f34e4c66" },
            { skill: "Game development", bgColor: "#f34e4c66" },
            { skill: "Networking", bgColor: "#f34e4c66" },
            { skill: "Linux", bgColor: "#f34e4c66" },
            { skill: "Python", bgColor: "#f34e4c66" },
            { skill: "Management", bgColor: "#f34e4c66" },
            { skill: "Agile", bgColor: "#f34e4c66" }
          ],
          iconSrc:
            "https://res.cloudinary.com/dldgxw9w6/image/upload/w_50,f_auto/v1664894107/1645707839053_t6cvrz.jpg"
        })}
      </VerticalTimeline>
    </div>
  );
};

export default Experience;
